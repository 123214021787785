import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';
import { getLoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/http';
import { PageRef } from '@wix/platform-editor-sdk';
import { EditorReadyFn, HandleActionFn, EditorScriptFlowAPI, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import loyaltyPageComponent from './components/LoyaltyPage/.component.json';

const TOKEN = 'TOKEN';
let flowAPI: EditorScriptFlowAPI;

const _editorReady: EditorReadyFn = async (editorSDK, _appDefinitionId, platformOptions, _flowAPI) => {
  flowAPI = _flowAPI;

  if (flowAPI?.experiments?.enabled?.('specs.membersArea.autopilotLogs')) {
    flowAPI?.sentry?.captureMessage?.('autopilot testing: editor ready');
  }

  if (platformOptions.firstInstall) {
    // disabled to check autopilot installation
    const pages = await editorSDK.document.pages.data.getAll(TOKEN);
    const page = pages.filter((data) => data.tpaApplicationId === platformOptions.initialAppData.applicationId)[0];
    const pageRef: PageRef = { id: page.id!, type: 'DESKTOP' };

    // Navigate to newly added page
    await editorSDK.pages.navigateTo(TOKEN, { pageRef });

    // Rename page to Loyalty Program name

    try {
      const loyaltyProgram = (await flowAPI.httpClient.request(getLoyaltyProgram({}))).data.loyaltyProgram;

      if (loyaltyProgram?.name) {
        editorSDK.document.pages.rename(TOKEN, {
          title: loyaltyProgram.name,
          pageRef,
        });
      }
    } catch (e) {
      flowAPI.reportError(e as Error);
    }
  }
};

const _handleAction: HandleActionFn = async () => {
  // Empty fn for Members Area integration kit
  if (flowAPI.experiments.enabled('specs.membersArea.autopilotLogs')) {
    flowAPI.sentry.captureMessage('autopilot testing: handle action');
  }
};

const appWithMembersArea = withMembersArea(
  { editorReady: _editorReady, handleAction: _handleAction },
  { membersAreaApps: [MA_APP_IDS.MY_REWARDS] },
);

export const editorReady = appWithMembersArea.editorReady;
export const handleAction = appWithMembersArea.handleAction;

export const getAppManifest: GetAppManifestFn = ({ appManifestBuilder }, editorSDK) => {
  appManifestBuilder.configureWidget(loyaltyPageComponent.id, (widgetBuilder) => {
    widgetBuilder
      .gfpp()
      .set('mainAction2', {
        label: flowAPI.translations.t('app.gfpp.manage-program'),
        onClick: () => {
          editorSDK.editor.openDashboardPanel(TOKEN, {
            url: 'loyalty-accounts/manage/?referralInfo=loyalty-gfpp',
            closeOtherPanels: false,
          });
        },
      })
      .set('help', {
        id: '5cff2a14-bdf9-47fc-9b44-ed6a2d5d3afa',
      });
  });

  return appManifestBuilder.build();
};
